<style lang="scss" scoped>
.file-select-btn {
    width: 80px;
}
</style>
<template>
    <div>
        <el-upload class="avatar-uploader"
                   action="https://up.qiniu.com"
                   :file-list="fileList"
                   :accept="accept"
                   :before-upload="handleChange"
                   :on-success="handleSuccess"
                   :on-error="handleError"
                   :data="uploadFile"
                   :disabled="disabled"
                   :on-remove="handleRemove">
            <el-button v-if="!customStyle"
                       slot="trigger"
                       :disabled="disabled"
                       :loading="loading"
                       class="file-select-btn">
                {{ title }}
            </el-button>
            <template v-else slot="trigger">
                <slot></slot>
            </template>
        </el-upload>
    </div>
</template>

<script type="text/ecmascript-6">
import conf from '@/config/index'

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            uploadFile: {},
            fileList: []
        }
    },
    props: {
        title: {
            type: String,
            default: '上传'
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        maxSize: {
            type: Number,
            default: 0
        },
        customStyle: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    //定义事件方法
    methods: {
        async handleChange(file) {
            if (this.maxSize > 0) {
                if (file.size > 1024 * 1024 * this.maxSize) {
                    this.$message('您上传的文件太大了，请上传不超过5M的文件');
                    return false;
                }
            }
            let upImgKey = '3.0/' + (conf.Debug ? 'develop' : 'release')
                + '/user/upImage/' + (+new Date()) + '/';
            let extend = ".jpg";
            if (file.name) {
                extend = file.name.split('.')[1];
            }
            let imgName = (+new Date()) + '.' + extend;
            let res = await this.http('/galaxyQiniu/getToken', {'upKey': upImgKey + imgName}, 'POST');
            if (res.success) {
                this.uploadFile = {key: (upImgKey + imgName), token: res.data.resultUrl};
            }
            this.loading = true;
            this.$emit('onStart')
        },
        handleError() {
            this.loading = false;
        },
        handleSuccess(res, file) {
            const result = {...file, fileUrl: `https://cdn.900etrip.com/${res.key}`}
            this.fileList.unshift(result);
            this.$emit('input', this.fileList.map(({fileUrl}) => fileUrl));
            this.$emit('onChange', this.fileList);
            this.loading = false;
        },
        handleRemove(file) {
            const index = this.fileList.findIndex(({fileUrl}) => fileUrl === file.url);
            this.fileList.splice(index, 1);
            this.$emit('input', this.fileList.map(({fileUrl}) => fileUrl));
            this.$emit('onChange', this.fileList);
        }
    }
}
</script>
